'use client'

import { chains } from '@lyra/web/constants/chains'
import { walletConnectCloudProjectId } from '@lyra/web/constants/env'
import { PrivyProvider } from '@privy-io/react-auth'
import React, { useEffect } from 'react'
import { useTheme } from 'tamagui'

import { baseUrl } from '../../constants/urls'

const LyraPrivyProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    console.debug('privy:', {
      env: process.env.NEXT_PUBLIC_PRIVY_ENV,
      appId: process.env.NEXT_PUBLIC_PRIVY_APP_ID,
    })
  }, [])

  const theme = useTheme()

  return (
    <PrivyProvider
      appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID!}
      config={{
        supportedChains: chains,
        appearance: {
          theme: 'dark',
          accentColor: theme.red?.get(),
          logo: `${baseUrl}/images/logo.png`,
          showWalletLoginFirst: true,
          walletList: [
            'detected_wallets',
            'metamask',
            'coinbase_wallet',
            'wallet_connect',
            'rabby_wallet',
            'phantom',
            'uniswap',
          ],
          loginMessage: 'Connect Wallet',
        },
        loginMethods: ['wallet', 'email', 'google'],
        externalWallets: {
          coinbaseWallet: {
            connectionOptions: 'eoaOnly',
          },
        },
        embeddedWallets: {
          createOnLogin: 'off',
        },
        walletConnectCloudProjectId,
      }}
    >
      {children}
    </PrivyProvider>
  )
}

export default LyraPrivyProvider

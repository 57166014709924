'use client'

import {
  BatchUserOperationCallData,
  deepHexlify,
  resolveProperties,
  SendUserOperationParameters,
  SmartContractAccount,
  UserOperationStruct_v6,
} from '@alchemy/aa-core'
import createAccount from '@lyra/core/api/createAccount'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { MAX_INT } from '@lyra/core/constants/contracts'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import erc20Abi from '@lyra/web/abis/erc20Abi'
import TransactionErrorModal from '@lyra/web/containers/common/TransactionErrorModal'
import { fetchDepositBalances } from '@lyra/web/utils/wallet'
import { mintOrRedeemYieldTokenImpl } from '@lyra/web/utils/yield'
import { getWalletClient } from '@wagmi/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Address,
  encodeFunctionData,
  Hash,
  isHex,
  PrivateKeyAccount,
  toHex,
  zeroAddress,
} from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'

import subaccountCreatorAbi from '../../abis/SubaccountCreator'
import { TransactionDisabledReason } from '../../constants/auth'
import { BridgeOptions, BridgeTransaction } from '../../constants/bridge'
import { DepositNetwork, lyraChain, mainnetChain } from '../../constants/chains'
import { lyraClient } from '../../constants/client'
import { lyraContractAddresses } from '../../constants/contracts'
import { isTestnet } from '../../constants/env'
import {
  CreateSubaccountParams,
  DEFAULT_CREATE_SUBACCOUNT_PARAMS,
} from '../../constants/subaccount'
import { DepositTokenId, TokenId } from '../../constants/tokens'
import {
  EnableTransactionOptions,
  EnableTransactionsResponse,
  TransactionOptions,
  TransactionStatusContext,
} from '../../constants/transactions'
import { HELP_BRIDGE_URL, HELP_ENABLE_DERIVE_URL } from '../../constants/urls'
import { UserWithAuth } from '../../constants/user'
import {
  DepositTokenBalances,
  EMPTY_DEPOSIT_TOKEN_BALANCES,
  EMPTY_LYRA_CHAIN_BALANCES,
  LyraDepositBalances,
  LyraWalletClient,
  WalletType,
} from '../../constants/wallet'
import {
  YieldTokenConfig,
  YieldTokenInputConfig,
  YieldTokenOutputConfig,
} from '../../constants/yield'
import useAuth from '../../hooks/useAuth'
import useEoaWallet from '../../hooks/useEoaWallet'
import {
  BridgeResponse,
  bridgeToLyraChain as bridgeToLyraChainImpl,
  fetchBridgeFromLyraChainTxs,
  getBridgeDurationEstimate,
  getIsBridgeTxPending,
} from '../../utils/bridge'
import {
  formatDepositNetworkName,
  getChainForDepositNetwork,
  getDepositNetworkForChainId,
} from '../../utils/chains'
import {
  fetchNeedsDepositApproval,
  getApproveDepositAndWithdrawalTxs,
  updateUserPendingBridges,
} from '../../utils/client/deposits'
import {
  exportSessionKeySecret,
  findDeviceSessionKeyInLocalStorage,
  generateSessionKeySecret,
  SESSION_KEY_EXPIRY_SECS,
  storeSessionPrivateKey,
} from '../../utils/client/deviceSessionKey'
import {
  getRegisterSessionKeyTx,
  getRevokeSessionKeyTx,
  waitForSessionKeyRegistration,
} from '../../utils/client/sessionKey'
import emptyFunction from '../../utils/emptyFunction'
import getAuthHeaders from '../../utils/getAuthHeaders'
import sleep from '../../utils/sleep'
import { waitForNewSubaccountId } from '../../utils/subaccounts'
import { getUtcMs, getUtcSecs } from '../../utils/time'
import {
  formatTokenBalance,
  getCollateralAddress,
  getCollateralForMarket,
  getCollateralForToken,
  getLyraTokenAddress,
} from '../../utils/tokens'
import {
  fetchLyraDepositBalances,
  fetchScwClient,
  getTransactionDisabledMessage,
  lyraEntrypoint,
} from '../../utils/wallet'
import { wagmiConfig } from '../WalletProvider'

// 5 minutes
const BALANCE_POLLING_INTERVAL_MS = 300_000

// 15 seconds
const PENDING_BRIDGES_POLLING_INTERVAL_MS = 10_000

type Props = {
  children?: React.ReactNode
}

export type TransactionContext = {
  address?: Address | undefined
  ownerAddress?: Address | undefined
  walletType?: WalletType
  disabledReason?: TransactionDisabledReason | null | undefined
  canTransact?: boolean
  sessionKey?: PrivateKeyAccount | undefined
  pendingBridges: BridgeTransaction[]
  balances: LyraDepositBalances
  depositBalances: DepositTokenBalances
  // lyra protocol
  tryEnableTradingAccount: (
    options: EnableTransactionOptions
  ) => Promise<EnableTransactionsResponse>
  registerDeveloperSessionKey: (
    address: Address,
    expiry: Date,
    label: string,
    options: TransactionOptions
  ) => Promise<Hash>
  revokeSessionKey: (address: Address, options: TransactionOptions) => Promise<Hash>
  revokeLocalDeviceSessionKey: (options: TransactionOptions) => Promise<Hash>
  // bridging
  bridgeToLyraChain: (
    network: DepositNetwork,
    token: DepositTokenId,
    amount: bigint,
    bridgeOptions: BridgeOptions,
    options: TransactionOptions
  ) => Promise<BridgeResponse>
  bridgeFromLyraChain: (
    receiver: Address,
    withdrawNetwork: DepositNetwork,
    token: TokenId,
    withdrawToken: DepositTokenId,
    amount: bigint,
    options: TransactionOptions
  ) => Promise<Hash>
  // yield
  mintYieldToken: (
    amount: bigint,
    config: YieldTokenConfig,
    inputConfig: YieldTokenInputConfig,
    outputConfig: YieldTokenOutputConfig,
    options: TransactionOptions
  ) => Promise<Hash>
  redeemYieldToken: (
    amount: bigint,
    config: YieldTokenConfig,
    inputConfig: YieldTokenInputConfig,
    outputConfig: YieldTokenOutputConfig,
    options: TransactionOptions
  ) => Promise<Hash>
  // testnet
  mintTestnetUsdc: () => Promise<bigint>
  // mutations
  mutateBalances: () => Promise<{
    balances: LyraDepositBalances
    depositBalances: DepositTokenBalances
  }>
  mutateAndUpdatePendingBridges: () => Promise<BridgeTransaction[]>
  createAndDepositFirstSubaccount: (options: EnableTransactionOptions) => Promise<{
    txHash: Hash
    sessionKey: PrivateKeyAccount
    subaccountId: number | undefined
    user: UserWithAuth
  }>
}

export const TransactionContext = React.createContext<TransactionContext>({
  pendingBridges: [],
  balances: EMPTY_LYRA_CHAIN_BALANCES,
  depositBalances: EMPTY_DEPOSIT_TOKEN_BALANCES,
  tryEnableTradingAccount: emptyFunction as any,
  registerDeveloperSessionKey: emptyFunction as any,
  revokeSessionKey: emptyFunction as any,
  revokeLocalDeviceSessionKey: emptyFunction as any,
  bridgeToLyraChain: emptyFunction as any,
  bridgeFromLyraChain: emptyFunction as any,
  mintYieldToken: emptyFunction as any,
  redeemYieldToken: emptyFunction as any,
  mintTestnetUsdc: emptyFunction as any,
  mutateBalances: emptyFunction as any,
  mutateAndUpdatePendingBridges: emptyFunction as any,
  createAndDepositFirstSubaccount: emptyFunction as any,
})

export default function TransactionProvider({ children }: Props) {
  const {
    user,
    session,
    isAuthenticated,
    deviceSessionKeys,
    accountDisabledReason,
    mutate: mutateAuth,
  } = useAuth()

  const walletType = isAuthenticated ? session.walletType : undefined

  const [sessionKey, setSessionKey] = useState<PrivateKeyAccount>()
  useEffect(() => {
    if (isAuthenticated) {
      findDeviceSessionKeyInLocalStorage(deviceSessionKeys).then((sessionKey) => {
        setSessionKey(sessionKey)
      })
    }
  }, [isAuthenticated, deviceSessionKeys])

  // External wallet
  const { walletClient: externalWalletClient, isConnected: externalIsConnected } = useEoaWallet()

  const disabledReason: TransactionDisabledReason | undefined =
    accountDisabledReason && accountDisabledReason !== 'invalid-auth-headers'
      ? accountDisabledReason
      : isAuthenticated && !externalIsConnected
      ? 'eoa-reconnect'
      : undefined

  // Balances
  // TODO @michaelxuwu move these out of TransactionProvider into hooks
  const [balances, setBalances] = useState<LyraDepositBalances>(EMPTY_LYRA_CHAIN_BALANCES)
  const [depositBalances, setDepositBalances] = useState<DepositTokenBalances>(
    EMPTY_DEPOSIT_TOKEN_BALANCES
  )

  // Pending deposits
  const [pendingBridges, setPendingBridges] = useState<BridgeTransaction[]>([])

  const walletClient: LyraWalletClient | undefined = externalWalletClient

  const address = !disabledReason ? user?.address : undefined
  const ownerAddress = !disabledReason ? user?.ownerAddress : undefined

  // TODO: @earthtojake move into swr hooks in relevant components
  const mutateBalances = useCallback(async () => {
    if (!isAuthenticated) {
      setBalances(EMPTY_LYRA_CHAIN_BALANCES)
      setDepositBalances(EMPTY_DEPOSIT_TOKEN_BALANCES)
      return {
        balances: EMPTY_LYRA_CHAIN_BALANCES,
        depositBalances: EMPTY_DEPOSIT_TOKEN_BALANCES,
      }
    } else {
      const [balances, depositBalances] = await Promise.all([
        fetchLyraDepositBalances(user.address, user.ownerAddress),
        fetchDepositBalances(user.ownerAddress),
      ])
      setBalances(balances)
      setDepositBalances(depositBalances)
      console.debug('mutateBalances', { balances, depositBalances })
      return { balances, depositBalances }
    }
    // IMPORTANT: should only update when user authenticates
  }, [isAuthenticated, user?.ownerAddress, user?.address])

  const mutateAndUpdatePendingBridges = useCallback(async () => {
    if (!isAuthenticated) {
      setPendingBridges([])
      return []
    } else {
      // Update pending bridges
      const updatedBridges = await updateUserPendingBridges()
      const pendingBridges = updatedBridges.filter((b) => getIsBridgeTxPending(b).isPending)
      console.debug('mutatePendingBridges', pendingBridges)
      setPendingBridges(pendingBridges)
      return pendingBridges
    }
  }, [isAuthenticated])

  /**
   * INTERNAL
   */

  /**
   * Send batched transactions
   * @notice make sure the sending wallet has at least 1 USD if the tx is not sponsored
   **/
  const _sendUserOperation = useCallback(
    async (
      walletClient: LyraWalletClient,
      sendParams: SendUserOperationParameters<SmartContractAccount>,
      {
        onTransactionStatusChange,
        skipCompleteStatus,
        confirmContext,
        inProgressContext,
        completeContext,
      }: TransactionOptions & {
        confirmContext?: TransactionStatusContext
        inProgressContext?: TransactionStatusContext
        completeContext?: TransactionStatusContext
      }
    ): Promise<Hash> => {
      if (!walletClient) {
        throw new Error('Wallet not ready')
      }

      const uoRequests = Array.isArray(sendParams.uo) ? sendParams.uo : [sendParams.uo]

      const batch: BatchUserOperationCallData = uoRequests.map((request) => {
        if (isHex(request)) {
          throw new Error('Direct Hex Operations not supported')
        }
        if (!request.target) {
          throw new Error('Missing target address')
        }
        return {
          target: request.target,
          data: request.data ?? '0x',
          value: request.value ?? BigInt(0),
        }
      })

      console.debug('_sendUserOperation', uoRequests)

      // hitting paymaster to generate message for user to sign
      onTransactionStatusChange('paymaster', { ...confirmContext })

      const client = await fetchScwClient(walletClient)

      const overriddenSendParams: SendUserOperationParameters<SmartContractAccount> = {
        ...sendParams,
        uo: batch,
      }

      const uoStruct = (await client.buildUserOperation(
        overriddenSendParams
      )) as UserOperationStruct_v6
      const resolvedStruct = await resolveProperties<UserOperationStruct_v6>(uoStruct)
      const requestToSign = deepHexlify(resolvedStruct)

      const hashToSign = client.account
        .getEntryPoint()
        .getUserOperationHash(deepHexlify(resolvedStruct))

      onTransactionStatusChange('confirm', {
        ...confirmContext,
        uoHash: hashToSign,
        uo: requestToSign,
      })

      // popup sign in wallet
      const request = await client.signUserOperation({ uoStruct })

      const uoHash = await client.sendRawUserOperation(request, lyraEntrypoint.address)

      // user has signed
      onTransactionStatusChange('in-progress', inProgressContext)

      const transactionHash = await client.waitForUserOperationTransaction({ hash: uoHash })
      console.debug('UO included in tx:', transactionHash)

      const receipt = await client.getUserOperationReceipt(uoHash)

      if (receipt === null || receipt.success === false) {
        throw new Error(`User operation failed: ${transactionHash}`)
      }

      if (!skipCompleteStatus) {
        onTransactionStatusChange('complete', {
          ...completeContext,
          txHash: transactionHash,
          chain: lyraChain,
        })
      }

      return transactionHash
    },
    []
  )

  /**
   * Switch wallet client to supported or specified network
   */

  const _switchNetwork = useCallback(
    async (
      networkish: DepositNetwork | 'any',
      options: EnableTransactionOptions
    ): Promise<LyraWalletClient> => {
      if (!walletClient) {
        console.log('_switchNetwork', { walletClient })
        throw new Error('Wallet not ready')
      }

      const chainId = await walletClient.getChainId()

      const switchToChain =
        networkish === 'any'
          ? !getDepositNetworkForChainId(chainId)
            ? mainnetChain
            : undefined
          : getChainForDepositNetwork(networkish).id !== chainId
          ? getChainForDepositNetwork(networkish)
          : undefined

      if (switchToChain) {
        console.debug('switching from chain id', chainId)

        options.onTransactionStatusChange('switch-network', {
          chain: switchToChain,
          title: `Switch to ${switchToChain.name}`,
        })

        await walletClient.switchChain({ id: switchToChain.id })

        // Dev note: account for delay between chain switching and walletClient object updating its state
        let retry = 100
        let newWalletClient: LyraWalletClient = walletClient
        while (
          !newWalletClient.chain ||
          (newWalletClient.chain.id !== switchToChain.id && retry > 0)
        ) {
          retry--
          newWalletClient = await getWalletClient(wagmiConfig)
          await sleep(500)
        }

        console.debug('switched to chain id', newWalletClient.chain.id)

        return newWalletClient
      } else {
        return walletClient
      }
    },
    [walletClient]
  )

  /**
   * Initializes an account:
   * - Creates subaccount
   * - Registers auth session key
   * - Registers device session key
   * - Approves set of collateral assets
   */
  const createAndDepositFirstSubaccount = useCallback(
    async (
      options: EnableTransactionOptions
    ): Promise<{
      txHash: Hash
      sessionKey: PrivateKeyAccount
      subaccountId: number | undefined
      user: UserWithAuth
    }> => {
      if (!address) {
        throw new Error('Wallet not ready')
      }

      if (!user) {
        throw new Error('Not authenticated')
      }

      // switch network
      const walletClient = await _switchNetwork('any', options)

      // IMPORTANT!!
      // create orderbook account
      await createAccount({
        address: user.address,
        ownerAddress: user.ownerAddress,
      })

      const txs: BatchUserOperationCallData = []

      // #1: Register device session key
      const sessionKeyExpiry = new Date()
      sessionKeyExpiry.setTime(sessionKeyExpiry.getTime() + SESSION_KEY_EXPIRY_SECS * 1000)
      const sessionPrivateKey = generatePrivateKey()
      const newSessionKey = privateKeyToAccount(sessionPrivateKey)

      console.debug('register session key:', newSessionKey.address, sessionKeyExpiry.getTime())
      txs.push(getRegisterSessionKeyTx(newSessionKey.address, sessionKeyExpiry))

      // #2.x: Approve contracts
      const approveTxs = getApproveDepositAndWithdrawalTxs(user.ownerAddress)
      for (const approveTx of approveTxs) {
        txs.push(approveTx)
      }

      // Note: Temporarily generate a private key for auth only
      const authKey = privateKeyToAccount(generatePrivateKey())
      const authKeyExpiry = new Date()
      authKeyExpiry.setFullYear(authKeyExpiry.getFullYear() + 100) // 100 years
      const authTimestamp = Date.now().toString()
      const authSignature = await authKey.signMessage({ message: authTimestamp })

      const sessionKeySecret = await generateSessionKeySecret()
      const exportedSessionKeySecret = await exportSessionKeySecret(sessionKeySecret)

      const shouldRegisterKeys = !user.hasAuth
      if (shouldRegisterKeys) {
        const res = await fetch('/api/auth/headers', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            authAddress: authKey.address,
            authSignature,
            authTimestamp,
            authExpirySecs: getUtcSecs(authKeyExpiry),
            sessionKey: newSessionKey.address,
            sessionKeySecret: exportedSessionKeySecret,
          }),
        })
        if (!res.ok) {
          throw new Error('Register auth key failed')
        }
      }

      // #4: create subaccount
      const shouldCreateSubaccount = options.createSubaccount ?? true
      if (shouldCreateSubaccount) {
        const firstSubaccountParams: CreateSubaccountParams =
          options?.firstSubaccountParams ?? DEFAULT_CREATE_SUBACCOUNT_PARAMS

        const firstSubaccountCollateral =
          firstSubaccountParams.marginType === 'PM'
            ? getCollateralForMarket(firstSubaccountParams.market)
            : firstSubaccountParams.token
            ? getCollateralForToken(firstSubaccountParams.token)
            : undefined

        if (firstSubaccountParams.marginType === 'PM' && !firstSubaccountCollateral) {
          throw new Error(`No collateral for market ${firstSubaccountParams.market}`)
        }

        const [collateralAddress, managerAddress] =
          firstSubaccountParams.marginType === 'PM' && firstSubaccountCollateral
            ? [
                getCollateralAddress(firstSubaccountCollateral),
                lyraContractAddresses.markets[firstSubaccountParams.market].portfolioManager,
              ]
            : firstSubaccountCollateral
            ? [
                getCollateralAddress(firstSubaccountCollateral),
                lyraContractAddresses.standardManager,
              ]
            : [zeroAddress, lyraContractAddresses.standardManager]

        if (!managerAddress) {
          throw new Error(
            `Margin type ${firstSubaccountParams.marginType} is not supported in market ${firstSubaccountParams.market}`
          )
        }
        // initial amount
        const firstSubaccountDepositAmount = firstSubaccountParams.amount ?? BigInt(0)
        const createSubaccountTxArgs: [`0x${string}`, bigint, `0x${string}`] = [
          collateralAddress,
          firstSubaccountDepositAmount,
          managerAddress,
        ]
        // Approve subaccount creator
        if (firstSubaccountParams.marginType === 'SM' && firstSubaccountParams.token) {
          console.debug('approving subaccountCreator')
          txs.push({
            target: getLyraTokenAddress(firstSubaccountParams.token),
            data: encodeFunctionData({
              abi: erc20Abi,
              functionName: 'approve',
              args: [lyraContractAddresses.subaccountCreator, MAX_INT],
            }),
          })
        }
        txs.push({
          target: lyraContractAddresses.subaccountCreator,
          data: encodeFunctionData({
            abi: subaccountCreatorAbi,
            functionName: 'createAndDepositSubAccount',
            args: createSubaccountTxArgs,
          }),
        })
      }
      // Note: skip complete status to allow session keys and subaccount to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Create Trading Account',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Creating Trading Account',
          },
        }
      )

      // register with db
      const authHeaders: LyraAuthHeaders = {
        'X-LyraWallet': address,
        'X-LyraTimestamp': authTimestamp.toString(),
        'X-LyraSignature': authSignature,
      }

      const [isAuthRegistered, isSessionKeyRegistered, newSubaccountId] = await Promise.all([
        waitForSessionKeyRegistration(address, authKey.address, authHeaders),
        waitForSessionKeyRegistration(address, newSessionKey.address, authHeaders),
        shouldCreateSubaccount ? waitForNewSubaccountId(user.address, [], authHeaders) : null,
      ])

      if (!isAuthRegistered || !isSessionKeyRegistered) {
        throw new Error('Session key not registered with orderbook')
      }

      // store encrypted private key in local storage
      await storeSessionPrivateKey(sessionPrivateKey, sessionKeySecret)

      const [newAuth] = await Promise.all([mutateAuth(), mutateBalances()])

      if (!newAuth.user || !newAuth.user.hasAuth) {
        throw new Error('initAccount failed')
      }

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Created Trading Account',
        })
      }

      return {
        txHash,
        sessionKey: newSessionKey,
        subaccountId: newSubaccountId ?? undefined,
        user: newAuth.user,
      }
    },
    [address, user, _switchNetwork, _sendUserOperation, mutateAuth, mutateBalances]
  )

  /**
   * Register device session key for user
   * Required when user signs in on new device after activating trading
   */
  const _registerDeviceSessionKey = useCallback(
    async (
      walletClient: LyraWalletClient,
      withApproveDeposit: boolean,
      options: TransactionOptions
    ) => {
      if (!address) {
        throw new Error('Wallet not ready')
      }

      if (!user?.hasAuth) {
        throw new Error('Not authenticated')
      }

      const sessionKeyExpiry = new Date()
      sessionKeyExpiry.setTime(sessionKeyExpiry.getTime() + SESSION_KEY_EXPIRY_SECS * 1000)
      const sessionPrivateKey = generatePrivateKey()
      const sessionKey = privateKeyToAccount(sessionPrivateKey)

      const txs = [getRegisterSessionKeyTx(sessionKey.address, sessionKeyExpiry)]

      if (withApproveDeposit) {
        const approvalTxs = getApproveDepositAndWithdrawalTxs(user.ownerAddress)
        for (const approvalTx of approvalTxs) {
          txs.push(approvalTx)
        }
      }

      // Note: skip complete status to allow session key to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Enable Derive on device',
            context: 'Create a session key on this device to use Derive.',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Enabling Derive',
          },
        }
      )

      const authHeaders = getAuthHeaders(user)
      const isRegistered = await waitForSessionKeyRegistration(
        address,
        sessionKey.address,
        authHeaders
      )
      if (!isRegistered) {
        throw new Error('Session key not registered with orderbook')
      }

      const sessionKeySecret = await generateSessionKeySecret()

      const exportedSessionKeySecret = await exportSessionKeySecret(sessionKeySecret)
      const res = await fetch('/api/register-session-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionKey: sessionKey.address,
          sessionKeySecret: exportedSessionKeySecret,
        }),
      })
      if (!res.ok) {
        throw new Error('Request failed')
      }
      // store encrypted private key in local storage
      await storeSessionPrivateKey(sessionPrivateKey, sessionKeySecret)
      await mutateAuth()

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Enabled Derive',
        })
      }

      return { txHash, sessionKey }
    },
    [address, user, _sendUserOperation, mutateAuth]
  )

  /**
   * Approves Lyra deposits:
   * - deposit module for deposits
   * - paymaster for self-paying transactions
   */
  const _approveDepositsAndPaymaster = useCallback(
    async (walletClient: LyraWalletClient, options: TransactionOptions) => {
      const txs = getApproveDepositAndWithdrawalTxs(walletClient.account.address)
      await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          confirmContext: {
            title: 'Enable Derive on device',
            context: 'Create a session key on this device to use Derive.',
            contextLink: {
              href: HELP_ENABLE_DERIVE_URL,
              label: 'Why is this required?',
              target: '_blank',
            },
          },
          inProgressContext: {
            title: 'Enabling Derive',
          },
          completeContext: {
            title: 'Enabled Derive',
          },
        }
      )
    },
    [_sendUserOperation]
  )

  /**
   * !! SUPER IMPORTANT
   *
   * checkWallet MUST be called before every transaction and enforces:
   * - kyt checks
   * - geoblocking
   * - invalid wallet configurations (e.g. wrong scw address)
   */

  const checkWallet = useCallback(() => {
    if (disabledReason) {
      console.debug('checkWallet disabled', disabledReason)
      throw new Error(getTransactionDisabledMessage(disabledReason))
    }

    if (!user) {
      throw new Error('Not authenticated')
    }

    if (!address || !ownerAddress) {
      console.debug('checkWallet address', { address, ownerAddress })
      throw new Error('Wallet not ready')
    }

    return {
      address,
      ownerAddress,
      user,
    }
  }, [disabledReason, user, address, ownerAddress])

  /**
   * TRANSACTION FUNCTIONS
   */

  /**
   * tryEnableTradingAccount does the following:
   * - registers device session key (if device has no session key)
   * - approves deposit module and paymaster for all collateral (if allowance below threshold)
   *
   * Required for the following transactions:
   * - submit orders
   * - deposit into subaccount
   * - withdraw from subaccount
   * - transfer between subaccounts
   */
  const tryEnableTradingAccount = useCallback(
    async (options: EnableTransactionOptions): Promise<EnableTransactionsResponse> => {
      const { user, address } = checkWallet()

      if (!user.hasAuth) {
        throw new Error('User has no trading accounts')
      }

      let thisSessionKey: PrivateKeyAccount
      let didExecuteTx = false

      const needsDepositApproval = await fetchNeedsDepositApproval(address, user.ownerAddress)
      if (!sessionKey) {
        // switch network
        const walletClient = await _switchNetwork('any', options)
        // register session key, optionally approve deposits if this is not set
        const { sessionKey: newSessionKey } = await _registerDeviceSessionKey(
          walletClient,
          needsDepositApproval,
          options
        )
        thisSessionKey = newSessionKey
        didExecuteTx = true
      } else {
        if (needsDepositApproval) {
          // switch network
          const walletClient = await _switchNetwork('any', options)
          // approve deposits
          await _approveDepositsAndPaymaster(walletClient, options)
          didExecuteTx = true
        }
        thisSessionKey = sessionKey
      }

      if (!didExecuteTx && !options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete')
      }

      return {
        sessionKey: thisSessionKey,
        user,
      }
    },
    [
      checkWallet,
      _switchNetwork,
      sessionKey,
      _registerDeviceSessionKey,
      _approveDepositsAndPaymaster,
    ]
  )

  /**
   * Register developer session key for account
   */
  const registerDeveloperSessionKey = useCallback(
    async (
      sessionKeyAddress: Address,
      expiry: Date,
      label: string,
      options: TransactionOptions
    ) => {
      const { user } = checkWallet()

      if (!user.hasAuth) {
        throw new Error('Subaccount not created')
      }

      const txs = [getRegisterSessionKeyTx(sessionKeyAddress, expiry)]

      const walletClient = await _switchNetwork('any', options)

      // Note: skip complete status to allow session key to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Confirm Register Session Key',
          },
          inProgressContext: {
            title: 'Registering Session Key',
          },
        }
      )

      const res = await fetch('/api/register-developer-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionKeyAddress,
          expiresAt: getUtcMs(expiry),
          label,
        }),
      })
      if (!res.ok) {
        throw new Error('Session key registration failed')
      }

      await mutateAuth()

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Successfully Registered Session Key',
        })
      }

      return txHash
    },
    [checkWallet, _sendUserOperation, mutateAuth, _switchNetwork]
  )

  /**
   * Revoke session key (developer or device) for account
   */
  const revokeSessionKey = useCallback(
    async (sessionKeyAddress: Address, options: TransactionOptions) => {
      const { user } = checkWallet()

      if (!user.hasAuth) {
        throw new Error('Subaccount not created')
      }

      const walletClient = await _switchNetwork('any', options)

      // Note: skip complete status to allow session key to sync with orderbook
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: getRevokeSessionKeyTx(sessionKeyAddress) },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: 'Confirm Revoke Session Key',
          },
          inProgressContext: {
            title: 'Revoking Session Key',
          },
        }
      )

      await mutateAuth()

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Successfully Revoked Session Key',
        })
      }

      return txHash
    },
    [checkWallet, _switchNetwork, _sendUserOperation, mutateAuth]
  )

  /**
   * Revoke device session key for browser
   */

  const revokeLocalDeviceSessionKey = useCallback(
    async (options: TransactionOptions) => {
      checkWallet()

      if (!sessionKey) {
        throw new Error('No device session key')
      }

      return await revokeSessionKey(sessionKey.address, options)
    },
    [checkWallet, sessionKey, revokeSessionKey]
  )

  /**
   * Bridges token to Lyra Chain and queues subaccount deposit
   * Note: This function assumes an account has been initialized
   */
  const bridgeToLyraChain = useCallback(
    async (
      network: DepositNetwork,
      token: DepositTokenId,
      amount: bigint,
      bridgeOptions: BridgeOptions,
      options: TransactionOptions
    ) => {
      checkWallet()

      const walletClient = await _switchNetwork(network, options)

      const response = await bridgeToLyraChainImpl(
        walletClient,
        network,
        token,
        amount,
        bridgeOptions,
        options
      )

      await Promise.all([mutateAuth(), mutateBalances(), mutateAndUpdatePendingBridges()])

      return response
    },
    [checkWallet, _switchNetwork, mutateAuth, mutateBalances, mutateAndUpdatePendingBridges]
  )

  const bridgeFromLyraChain = useCallback(
    async (
      receiver: Address,
      withdrawNetwork: DepositNetwork,
      token: TokenId,
      withdrawToken: DepositTokenId,
      amount: bigint,
      options: TransactionOptions
    ) => {
      const { ownerAddress, address } = checkWallet()

      // switch to withdraw network
      const walletClient = await _switchNetwork(withdrawNetwork, options)

      // check if user needs to approve paymaster
      // edge case if user immediately deposits and then withdrawals
      const needsPaymasterApproval = await fetchNeedsDepositApproval(address, ownerAddress)
      if (needsPaymasterApproval) {
        // approve deposits
        await _approveDepositsAndPaymaster(walletClient, { ...options, skipCompleteStatus: true })
      }

      // !!SUPER IMPORTANT!!
      // addres is always smart contract wallet address
      // receiver is defined (typically owner address)
      // FAILURE TO SET THE RECEIVER ADDRESS CORRECTLY WILL RESULT IN LOSS OF FUNDS
      const txs = await fetchBridgeFromLyraChainTxs({
        address,
        receiver,
        withdrawNetwork,
        token,
        withdrawToken,
        amount,
      })

      // Use flat fee for bundler
      // Note: skip complete status to allow registering withdraw with backend
      const txHash = await _sendUserOperation(
        walletClient,
        { uo: txs },
        {
          ...options,
          skipCompleteStatus: true,
          confirmContext: {
            title: `Withdraw ${formatTokenBalance(amount, token)} to ${formatDepositNetworkName(
              withdrawNetwork
            )}`,
            context: `Withdrawals typically take ${getBridgeDurationEstimate(withdrawNetwork)}.`,
            contextLearnMoreHref: HELP_BRIDGE_URL,
          },
          inProgressContext: {
            title: `Withdrawing ${formatTokenBalance(amount, token)} to ${formatDepositNetworkName(
              withdrawNetwork
            )}`,
          },
        }
      )

      const res = await fetch('/api/withdraw', {
        method: 'POST',
        body: JSON.stringify({
          amount: toHex(amount),
          network: withdrawNetwork,
          token: withdrawToken,
          txHash,
        }),
      })

      if (!res.ok) {
        throw new Error(
          'Withdraw was successful but Derive failed to log your transaction. Please contact support.'
        )
      }

      await lyraClient.waitForTransactionReceipt({ hash: txHash })
      await Promise.all([mutateAuth(), mutateBalances(), mutateAndUpdatePendingBridges()])

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Successfully Withdrew ${formatTokenBalance(amount, token)}`,
          context: `Your funds will be available in your connected wallet (${formatTruncatedAddress(
            ownerAddress
          )}) on ${formatDepositNetworkName(withdrawNetwork)} in ${getBridgeDurationEstimate(
            withdrawNetwork
          )}.`,
        })
      }

      return txHash
    },
    [
      checkWallet,
      _sendUserOperation,
      mutateAuth,
      mutateBalances,
      mutateAndUpdatePendingBridges,
      _switchNetwork,
      _approveDepositsAndPaymaster,
    ]
  )

  const mintYieldToken = useCallback(
    async (
      amount: bigint,
      config: YieldTokenConfig,
      inputConfig: YieldTokenInputConfig,
      outputConfig: YieldTokenOutputConfig,
      options: TransactionOptions
    ) => {
      checkWallet()

      // switch networks
      const walletClient = await _switchNetwork(inputConfig.network, options)

      return await mintOrRedeemYieldTokenImpl(
        walletClient,
        amount,
        true /* isMint */,
        config,
        inputConfig,
        outputConfig,
        options
      )
    },
    [checkWallet, _switchNetwork]
  )

  const redeemYieldToken = useCallback(
    async (
      amount: bigint,
      config: YieldTokenConfig,
      inputConfig: YieldTokenInputConfig,
      outputConfig: YieldTokenOutputConfig,
      options: TransactionOptions
    ) => {
      checkWallet()

      // switch networks
      const walletClient = await _switchNetwork(inputConfig.network, options)

      return await mintOrRedeemYieldTokenImpl(
        walletClient,
        amount,
        false /* isMint */,
        config,
        inputConfig,
        outputConfig,
        options
      )
    },
    [checkWallet, _switchNetwork]
  )

  /**
   * Mint testnet tokens into smart contract wallet
   */
  const mintTestnetUsdc = useCallback(async () => {
    if (!isTestnet) {
      throw new Error('Can only mint USDC on testnet')
    }
    console.debug('minting testnet usdc')
    await fetch('/api/mint', { method: 'POST' })

    let usdcBalance = BigInt(0)
    let remAttempts = 20 // poll 10 seconds
    while (usdcBalance === BigInt(0) && remAttempts >= 0) {
      await sleep(500)
      const newBalances = await mutateBalances()
      usdcBalance = newBalances.balances.USDC
      console.debug('polling usdc balance', usdcBalance)
      remAttempts -= 1
    }

    if (usdcBalance === BigInt(0)) {
      throw new Error('Failed to mint USDC on testnet')
    }

    return usdcBalance
  }, [mutateBalances])

  /**
   * POLLING
   */

  // Poll balances
  useEffect(() => {
    const interval = setInterval(mutateBalances, BALANCE_POLLING_INTERVAL_MS)
    mutateBalances()
    return () => {
      clearInterval(interval)
    }
  }, [mutateBalances, user?.address])

  // Poll pending bridges
  useEffect(() => {
    // Initial fetch when wallet changes
    mutateAndUpdatePendingBridges()

    let interval: NodeJS.Timeout
    if (pendingBridges.length) {
      // Only poll when there are pending bridges in progress (triggered by bridgeTo/FromLyraChain)
      console.debug('polling bridges', pendingBridges.length)
      interval = setInterval(mutateAndUpdatePendingBridges, PENDING_BRIDGES_POLLING_INTERVAL_MS)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [mutateAndUpdatePendingBridges, pendingBridges.length, user?.address])

  // Whenever pending bridges change, update balances
  useEffect(() => {
    mutateBalances()
  }, [mutateBalances, pendingBridges.length])

  const value = useMemo(() => {
    return {
      address,
      ownerAddress,
      walletType,
      disabledReason,
      pendingBridges,
      balances,
      depositBalances,
      sessionKey,
      tryEnableTradingAccount,
      registerDeveloperSessionKey,
      revokeSessionKey,
      revokeLocalDeviceSessionKey,
      bridgeToLyraChain,
      bridgeFromLyraChain,
      mintYieldToken,
      redeemYieldToken,
      mintTestnetUsdc,
      mutateBalances,
      mutateAndUpdatePendingBridges,
      createAndDepositFirstSubaccount,
    }
  }, [
    address,
    ownerAddress,
    walletType,
    disabledReason,
    pendingBridges,
    balances,
    depositBalances,
    sessionKey,
    tryEnableTradingAccount,
    registerDeveloperSessionKey,
    revokeSessionKey,
    revokeLocalDeviceSessionKey,
    bridgeToLyraChain,
    bridgeFromLyraChain,
    mintYieldToken,
    redeemYieldToken,
    mintTestnetUsdc,
    mutateBalances,
    mutateAndUpdatePendingBridges,
    createAndDepositFirstSubaccount,
  ])

  return (
    <TransactionContext.Provider value={value}>
      {accountDisabledReason === 'invalid-auth-headers' ? <TransactionErrorModal /> : null}
      {children}
    </TransactionContext.Provider>
  )
}
